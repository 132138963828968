/* Die müssen vor der basis-CSS eingebunden werden */
import "select2/dist/css/select2.min.css"
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css"
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css"
import "daterangepicker/daterangepicker.css"
import "admin-resources/jquery.vectormap/jquery-jvectormap-1.2.2.css"
import "jstree/dist/themes/default/style.min.css"
import "bootstrap-timepicker/css/bootstrap-timepicker.min.css"
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css"
import "flatpickr/dist/flatpickr.min.css"

import "../styles/fonts/fonts.css";
import "normalize.css";
import '../styles/styles.scss'
import '../styles/styles.less'
import '../styles/styles.pcss'

import $ from "expose-loader?exposes=$,jQuery!jquery";
import "simplebar"

import "select2"
$.fn.select2.defaults.set('minimumResultsForSearch', 10);


import '@hyper/assets/js/hyper-config.js'
// eslint-disable-next-line import/namespace
import { initHyperMain } from '@hyper/assets/js/hyper-main.js'
import '@hyper/assets/js/hyper-layout.js'


initHyperMain(document)
window.loadDragula = () => import("@hyper/assets/js/ui/component.dragula.js");
window.getApexCharts = () => import("apexcharts")
window.loadJstree = () => import("jstree")
window.loadBootstrapTimepicker = () => import("bootstrap-timepicker")
window.loadBootstrapDatepicker = () => import("bootstrap-datepicker")
window.getSickcall = () => import("./modules/sickcall");
window.getVacation = () => import("./modules/vacation");
window.getDashboard = () => import("./modules/dashboard");
window.getAZEDashboard = () => import("./modules/aze_dashboard");
/* das*/
